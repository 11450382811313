import axios from "axios";
import queryString from "query-string";
import { AppointmentSlots, ServiceDeliveredOptions } from "../components/ProcessAppointmentForm/helpers";
import { RoomType } from "../types";
import { CustomFeature } from "./clinicianProfileService";

const schedServiceApi = axios.create({
  baseURL: process.env.REACT_APP_SCHED_SERVICE_URL!,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: (status) => status < 400
});

export enum TelehealthRoomType {
  Video = "video",
  Voice = "voice"
}

export const getTelehealthRoomType = async (roomId: string) =>
  schedServiceApi.get<{ type: TelehealthRoomType }>(`/telehealth-rooms/${roomId}/type`);

export interface WaitroomParticipant {
  participantId: string; // participant's identity
  name: string;
  isHost: boolean;
  isAdmitted: boolean;
  isConnected: boolean;
  isRemoved: boolean;
  isCameraPermissionsDenied?: boolean;
  isMicrophonePermissionsDenied?: boolean;
}

export interface Account {
  customFeatures: CustomFeature[];
}

interface PostJoinWaitroomResponse {
  participant: WaitroomParticipant;
  token: string; // used to get waitroom only
  account: Account;
}

/**
 * Public endpoint for clients to join waitroom
 */
export const postJoinWaitroom = async (
  name: string,
  roomId: string,
  mediaError?: string,
  isCameraPermissionsDenied?: boolean,
  isMicrophonePermissionsDenied?: boolean
) =>
  schedServiceApi.post<PostJoinWaitroomResponse>(`/telehealth-rooms/${roomId}/participants`, {
    mediaError,
    name,
    isCameraPermissionsDenied,
    isMicrophonePermissionsDenied
  });

interface GetWaitroomParticipantForParticipantResponse {
  participant: WaitroomParticipant;
  participants: WaitroomParticipant[];
  token?: string; // returns Twilio token if participant.isAdmitted === true
}

/**
 * Protected endpoint for participants to get their own participant
 */
export const getWaitroomParticipantForParticipant = async ({
  roomId,
  participantId,
  token,
  returnToken
}: {
  roomId: string;
  participantId: string;
  token: string; // from postJoinWaitroom only
  returnToken: boolean;
}) =>
  schedServiceApi.get<GetWaitroomParticipantForParticipantResponse>(
    `/telehealth-rooms/${roomId}/participants/${participantId}${returnToken ? "?returnToken=1" : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export interface GetAccessTokenResponse {
  roomId: string;
  token: string;
  participants: WaitroomParticipant[];
  identity: string;
  roomSid: string;
  roomType: RoomType;
  appointment: AppointmentSlots;
}

/**
 * Protected endpoint for clinicians to join room
 */
export const postGetHostAccessToken = async (
  token: string,
  roomId: string,
  mediaError?: string,
  isCameraPermissionsDenied?: boolean,
  isMicrophonePermissionsDenied?: boolean
) =>
  schedServiceApi.post<GetAccessTokenResponse>(
    `/telehealth-rooms/${roomId}/hosts`,
    { mediaError, isCameraPermissionsDenied, isMicrophonePermissionsDenied },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

interface GetWaitroomParticipantsForHostResponse {
  participants: WaitroomParticipant[];
}

/**
 * Protected endpoint for clinicians to get waitroom participants
 */
export const getWaitroomParticipantsForHost = async (token: string, roomId: string) =>
  schedServiceApi.get<GetWaitroomParticipantsForHostResponse>(`/telehealth-rooms/${roomId}/participants`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

interface AdmitParticipantsResponse {
  participants?: WaitroomParticipant[];
}

/**
 * Protected endpoint for clinicians to admit participants to the room
 */
export const admitParticipantsToRoom = async (token: string, roomId: string, participantIds: string[]) =>
  schedServiceApi.patch<AdmitParticipantsResponse>(
    `/telehealth-rooms/${roomId}/participants`,
    { participantIds },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

interface RemoveParticipantResponse {
  participants?: WaitroomParticipant[];
}

/**
 * Protected endpoint for clinicians (host) to remove participant in the room
 */
export const removeParticipant = async (token: string, roomId: string, participantSid: string) =>
  schedServiceApi.delete<RemoveParticipantResponse>(`/telehealth-rooms/${roomId}/participants/${participantSid}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

/**
 * Protected endpoint for clinicians (hosts) to end telehealth room
 */
export const endRoom = async (token: string, roomId: string) =>
  schedServiceApi.put<void>(
    `/telehealth-rooms/${roomId}:completeRoom`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export interface GetAppointmentVoiceCallAccessTokenResponse {
  token: string;
  mobileNumber: string;
  firstName: string;
}

/**
 * Protected endpoint for clinicians to make an appointment voice call
 */
export const getAppointmentVoiceCallAccessToken = async (token: string, appointmentId: string) =>
  schedServiceApi.put<GetVoiceCallAccessTokenResponse>(
    `/telehealth-voice/appointments/${appointmentId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export interface GetVoiceCallAccessTokenResponse {
  token: string;
  mobileNumber: string;
  firstName: string;
  appointment: AppointmentSlots;
}

/**
 * Protected endpoint for clinicians to make an appointment voice call
 */
export const getVoiceCallAccessToken = async (token: string) =>
  schedServiceApi.put<GetVoiceCallAccessTokenResponse>(
    `/telehealth-voice/token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export const getAppointmentById = async (token: string, accountId: string, appointmentId: string) =>
  schedServiceApi.get(`/accounts/${accountId}/appointments/${appointmentId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getAppointmentTypeById = async (token: string, sessionTypeId: string) =>
  schedServiceApi.get(`/session-types/${sessionTypeId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export enum ParticipantType {
  OneToOne = "oneToOne",
  Group = "group"
}

export enum AssignmentMode {
  All = "all",
  Practice = "practice",
  Selected = "selected",
  AllMentors = "allMentors",
  AutoAssign = "autoAssign",
  Single = "single"
}

export const getAppointmentTypesByClinicianId = async (
  token: string,
  isAdmin: boolean,
  clinicianId: string,
  participantType: ParticipantType | undefined
) => {
  if (isAdmin && clinicianId) {
    return schedServiceApi.get(`/clinicians/${clinicianId}/session-types`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  const queryParamsStr = queryString.stringify({
    participantType,
    assignmentMode: participantType === ParticipantType.Group ? AssignmentMode.All : AssignmentMode.Practice
  });

  return schedServiceApi.get(`/session-types${queryParamsStr ? `?${queryParamsStr}` : ""}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export interface ProcessAppointmentPayload {
  serviceDelivered?: ServiceDeliveredOptions;
  appointmentTypeId?: string;
  deliveryType?: string;
  asAdmin: boolean;
  providerId?: string;
  funder?: string;
  mbsCode?: string;
  waiveCancellationFee: boolean;
}

export interface SubmitForReviewPayload extends ProcessAppointmentPayload {
  reviewNote?: string;
}

export const postProcessAppointment = async (
  token?: string,
  appointmentId?: string,
  clinicianId?: string,
  payload?: ProcessAppointmentPayload
) =>
  schedServiceApi.post(`/clinicians/${clinicianId}/appointments/${appointmentId}/process`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const postSubmitForReview = async (
  token?: string,
  appointmentId?: string,
  clinicianId?: string,
  payload?: SubmitForReviewPayload
) =>
  schedServiceApi.post(`/clinicians/${clinicianId}/appointments/${appointmentId}/review`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const postRequestHelp = async (
  token: string,
  roomId: string,
  payload: {
    senderId?: string;
    name?: string;
  }
) =>
  schedServiceApi.post(`/telehealth-rooms/${roomId}:requestHelp`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const postClientRequestHelp = async (
  roomId: string,
  payload: {
    senderId?: string;
    name?: string;
  }
) => schedServiceApi.post(`/telehealth-rooms/${roomId}:clientRequestHelp`, payload);
