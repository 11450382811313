import { makeStyles } from "@material-ui/core";
import { useFetchClinicianProfileById } from "../../hooks/useGetClinicianProfileById";
import useHeight from "../../hooks/useHeight/useHeight";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import { CustomFeature } from "../../services/clinicianProfileService";
import { useAppState } from "../../state";
import AskForHelpButton from "../Buttons/AskForHelpButton/AskForHelpButton";
import { ChatProvider } from "../ChatProvider";
import ErrorDialog from "../Dialogs/ErrorDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import MenuBar from "../MenuBar/MenuBar";
import MobileTopMenuBar from "../MobileTopMenuBar/MobileTopMenuBar";
import NewJoinRequestNotification from "../NewJoinRequestNotification";
import { ParticipantProvider } from "../ParticipantProvider";
import PreJoinScreens from "../PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "../ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "../RecordingNotifications/RecordingNotifications";
import Room from "../Room/Room";
import WaitingRoom from "../Room/WaitingRoom";
import { useVideoContext } from "../VideoProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateRows: "1fr auto"
  },
  main: {
    overflow: "hidden",
    paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
    background: "black",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px` // Leave some space for the mobile header and footer
    }
  },
  helpButton: {
    display: "flex",
    position: "absolute",
    zIndex: 1,
    top: "16px",
    left: "24px",

    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const BaseVideoApp = () => {
  const classes = useStyles();

  const { error, setError, success, setSuccess, user, appointment } = useAppState();
  const roomState = useRoomState();
  const { isCurrentUserInWaitingRoom, account } = useVideoContext();
  const { clinician } = useFetchClinicianProfileById(appointment?.clinicianId);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SuccessDialog dismiss={() => setSuccess(null)} successContent={success} />
      <ParticipantProvider>
        <ChatProvider>
          <div className={classes.container} style={{ height }}>
            {roomState === "connected" ? (
              <main className={classes.main}>
                <ReconnectingNotification />
                <RecordingNotifications />
                {user?.isClinician && <NewJoinRequestNotification />}
                {((user?.isClinician && clinician?.customFeatures?.includes(CustomFeature.Raise)) ||
                  account?.customFeatures?.includes(CustomFeature.Raise)) && (
                  <div className={classes.helpButton}>
                    <AskForHelpButton />
                  </div>
                )}
                <MobileTopMenuBar />
                <Room />
                <MenuBar />
              </main>
            ) : isCurrentUserInWaitingRoom ? (
              <WaitingRoom />
            ) : (
              <PreJoinScreens />
            )}
          </div>
        </ChatProvider>
      </ParticipantProvider>
    </>
  );
};

export default BaseVideoApp;
