import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { ConnectOptions, CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack, Room } from "twilio-video";
import { ErrorCallback, SuccessCallback } from "../../types";
import { SelectedParticipantProvider } from "./useSelectedParticipant/useSelectedParticipant";

import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useDevices, { DeviceInfo } from "../../hooks/useDevices/useDevices";
import {
  Account,
  admitParticipantsToRoom,
  getTelehealthRoomType,
  postGetHostAccessToken,
  postJoinWaitroom,
  TelehealthRoomType,
  WaitroomParticipant
} from "../../services/schedService";
import { useAppState } from "../../state";
import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useBackgroundSettings, { BackgroundSettings } from "./useBackgroundSettings/useBackgroundSettings";
import useHandleRoomDisconnection from "./useHandleRoomDisconnection/useHandleRoomDisconnection";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRestartAudioTrackOnDeviceChange from "./useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange";
import useRoom from "./useRoom/useRoom";
import useScreenShareToggle from "./useScreenShareToggle/useScreenShareToggle";
import { useWaitroom } from "./useWaitroom/useWaitroom";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext extends DeviceInfo {
  room: Room | null;
  allParticipants: WaitroomParticipant[];
  participantsInWaitroom: WaitroomParticipant[];
  userParticipant?: WaitroomParticipant;
  updateParticipantsList: (waitroomParticipants: WaitroomParticipant[]) => void;
  isCurrentUserInWaitingRoom: boolean;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  hasAttemptedAcquireLocalTracks: boolean;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  acquireLocalMedia: (telehealthRoomType: TelehealthRoomType) => Promise<void>;
  mediaError?: Error;
  isFetching: boolean;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  isAdminControlOpen: boolean;
  setIsAdminControlOpen: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundSettings: BackgroundSettings;
  setBackgroundSettings: (settings: BackgroundSettings) => void;
  handleAcceptClientRequestsToJoinRoom: (participantIds: string[]) => Promise<void>;
  joinRoom: (name: string, roomId: string) => Promise<void>;
  leaveRoom: () => void;
  initialiseTelehealthRoomType: (roomId: string) => Promise<TelehealthRoomType>;
  telehealthRoomType?: TelehealthRoomType;
  account?: Account;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  onSuccess: SuccessCallback;
  children: ReactNode;
}

export function VideoProvider({ options, children, onError = () => {}, onSuccess = () => {} }: VideoProviderProps) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { search } = useLocation();

  const { type }: { type?: TelehealthRoomType } = queryString.parse(search);

  const [isFetching, setIsFetching] = useState(false);
  const [mediaError, setMediaError] = useState<Error>();
  const [telehealthRoomType, setTelehealthRoomType] = useState<TelehealthRoomType | undefined>(type);
  const [account, setAccount] = useState<Account>();

  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const {
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
    hasAudioInputDevices,
    hasVideoInputDevices,
    getDevices
  } = useDevices();
  const {
    localTracks,
    getLocalVideoTrack,
    hasAttemptedAcquireLocalTracks,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks
  } = useLocalTracks(getDevices);
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
  const { user, setError, setRoomType, setAppointment } = useAppState();

  const initialiseTelehealthRoomType = async (roomId: string) => {
    const type = await getTelehealthRoomType(roomId)
      .then((res) => res.data.type)
      .catch((ex) => {
        if (ex instanceof AxiosError) {
          console.error({
            code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
            message: ex.response?.data?.message || ex.message,
            name: ex.name
          });
        }
      });

    const newTelehealthRoomType = type || telehealthRoomType || TelehealthRoomType.Video;
    setTelehealthRoomType(newTelehealthRoomType);

    return newTelehealthRoomType;
  };

  const joinRoom = async (name: string, roomId: string) => {
    setIsFetching(true);
    const isCameraPermissionsDenied =
      !localTracks.some((track) => track.kind === "video") && !videoInputDevices.some((device) => device.label);
    const isMicrophonePermissionsDenied = !localTracks.some((track) => track.kind === "audio");

    if (isAuthenticated && user?.isClinician) {
      let auth0Token: string;
      try {
        auth0Token = await getAccessTokenSilently();
      } catch (ex) {
        console.error(ex);
        return setError({
          code: 400,
          message: "Error while trying to connect to room",
          name: "Failed to Join"
        });
      }

      const postGetHostAccessTokenResponse = await postGetHostAccessToken(
        auth0Token,
        roomId,
        mediaError?.toString(),
        isCameraPermissionsDenied,
        isMicrophonePermissionsDenied
      )
        .then((res) => res.data)
        .catch((ex) => {
          if (ex instanceof AxiosError) {
            setError({
              code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
              message: ex.response?.data?.message || ex.message,
              name: ex.name
            });
          }
        });

      if (postGetHostAccessTokenResponse) {
        const { identity, participants, roomSid, roomType, token, appointment } = postGetHostAccessTokenResponse;

        const hostParticipant = participants.find(({ participantId, isHost }) => isHost && participantId === identity);

        if (!hostParticipant) {
          console.log(`identity: ${identity} | roomSid: ${roomSid}`);
          console.error("Failed to find host participant in get token response");

          setError({
            code: 500,
            message: "Error connecting to waitroom. Please try again.",
            name: "WaitroomHostError"
          });

          return;
        }

        setAppointment(appointment);
        setRoomType(roomType);
        await connect(token);
        initialiseHostWaitroom(roomId, participants, hostParticipant);
      }
    } else {
      const postJoinWaitroomResponse = await postJoinWaitroom(
        name,
        roomId,
        mediaError?.toString(),
        isCameraPermissionsDenied,
        isMicrophonePermissionsDenied
      )
        .then((res) => res.data)
        .catch((ex) => {
          if (ex instanceof AxiosError) {
            setError({
              code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
              message: ex.response?.data?.message || ex.message,
              name: ex.name
            });
          }
        });

      if (postJoinWaitroomResponse) {
        const { participant, token, account } = postJoinWaitroomResponse;

        setAccount(account);
        initialiseParticipantWaitroom(roomId, participant, token);
      }
    }

    setIsFetching(false);
  };

  const {
    participants: waitroomParticipants,
    userParticipant,
    isCurrentUserInWaitingRoom,
    initialiseHostWaitroom,
    initialiseParticipantWaitroom,
    setParticipants,
    resetWaitroom
  } = useWaitroom({
    room,
    initialiseVideoRoom: connect,
    rejoinRoom: joinRoom
  });

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(room, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare, {
    onError,
    onSuccess
  });
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
  // for admin-use
  const [isAdminControlOpen, setIsAdminControlOpen] = useState(false);

  const videoTrack = localTracks.find((track) => !track.name.includes("screenshare") && track.kind === "video") as
    | LocalVideoTrack
    | undefined;
  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  const acquireLocalMedia = async (telehealthRoomType: TelehealthRoomType) => {
    try {
      await getAudioAndVideoTracks(telehealthRoomType);
    } catch (ex) {
      console.dir(ex);
      console.error("Error acquiring local media");
      setMediaError(ex as Error);
    }
  };

  /**
   * **Warning**
   * Only admins can use this function
   */
  const handleAcceptClientRequestsToJoinRoom = async (participantIds: string[]) => {
    if (!room || !user?.isClinician) {
      return;
    }

    const token = await getAccessTokenSilently();

    const {
      data: { participants }
    } = await admitParticipantsToRoom(token, room.name, participantIds);

    if (participants) {
      setParticipants(participants);
    }
  };

  const leaveRoom = useCallback(() => {
    resetWaitroom();

    if (room) {
      room.disconnect();
    }
  }, [room, resetWaitroom]);

  const participantsInWaitroom = useMemo(() => {
    if (!room) {
      return waitroomParticipants;
    }

    const roomParticipants = Array.from(room.participants.values());

    return waitroomParticipants.filter(
      ({ participantId, isHost, isAdmitted, isConnected, isRemoved }) =>
        !isHost &&
        !isConnected &&
        !isRemoved &&
        (!isAdmitted || !roomParticipants.some(({ identity }) => participantId === identity))
    );
  }, [room, waitroomParticipants]);

  return (
    <VideoContext.Provider
      value={{
        room,
        allParticipants: waitroomParticipants,
        participantsInWaitroom,
        userParticipant,
        updateParticipantsList: setParticipants,
        isCurrentUserInWaitingRoom,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        hasAttemptedAcquireLocalTracks,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        acquireLocalMedia,
        mediaError,
        isFetching,
        isSharingScreen,
        toggleScreenShare,
        isBackgroundSelectionOpen,
        setIsBackgroundSelectionOpen,
        isAdminControlOpen,
        setIsAdminControlOpen,
        backgroundSettings,
        setBackgroundSettings,
        handleAcceptClientRequestsToJoinRoom,
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
        hasAudioInputDevices,
        hasVideoInputDevices,
        joinRoom,
        leaveRoom,
        initialiseTelehealthRoomType,
        telehealthRoomType,
        account
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error("useVideoContext must be used within a VideoProvider");
  }
  return context;
};
